<template>
    <div>
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules">
                    <el-form-item label="场站管理单位:" prop="name" :label-width="formLabelWidth">
                        <el-input v-model="diaform.name"></el-input>
                    </el-form-item>
                    <el-form-item label="备注:" prop="remark" :label-width="formLabelWidth">
                        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="diaform.remark">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 对话框类型
            type: '',
            title: '',

            diaform: {
                name: '',
                remark: ''
            },
            dialogVisible: false,
            formLabelWidth: '120px',

            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入场站管理单位',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('dialogForm')
            }
        }
    },
    methods: {
        // 打开对话框
        init(option, type) {
            this.dialogVisible = true
            this.type = type || 'add'
            if (this.type == 'update') {
                this.title = '编辑'
                this.$nextTick(() => {
                    this.diaform = {
                      ...this.diaform,
                      ...JSON.parse(JSON.stringify(option))
                    }
                })
            } else {
                this.title = '新增'
            }
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.dialogForm.validate(valid => {
                if (valid) {
                    let url = ''
                    if (this.type == 'add') {
                        url = '/basestationmunit/save'
                    } else {
                        url = '/basestationmunit/update'
                    }
                    let option = this.diaform
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
</style>